<template>
  <div class="chat" ref="currentMessage">
    <div class="profile-ai">
      <img v-if="chat.isAi" :src="require('@/assets/icons/ai/bot.png')" alt=""/>
      <img v-else :src="user.avatar || NoAvatar" alt=""/>
    </div>
    <div class="message" ref="message">
      <vue-mathjax :formula="chat.value" :safe="false" v-if="chat.isAi" @renderFinish="$emit('renderFinish')"></vue-mathjax>
      <template v-else>{{ chat.value }}</template>
      <div v-if="chat.isAi && !chat.isDefault && chat.value !=='Đang trả lời....' " class="copy" @click="copyText(chat.value)"><img :src="require('@/assets/icons/ai/copy.svg')"></div>
    </div>
  </div>
</template>

<script>
// // eslint-disable-next-line import/named
// import {nextTick, onMounted, ref} from 'vue'
import {mapState} from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    chat: {
      type: Object || null
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  data () {
    return {NoAvatar}
  },
  // setup(props) {
  //   const currentMessage = this.$ref(null)
  //
  //   this.$mount(async () => {
  //     await this.$nextTick()
  //     const div = currentMessage.value
  //     div.scrollIntoView({behavior: 'smooth'})
  //   })
  //
  //   return {
  //     currentMessage
  //   }
  // },
  // setup(props) {
  //   const currentMessage = ref(null)
  //
  //   onMounted(async () => {
  //     await nextTick()
  //     const div = currentMessage.value
  //     div.scrollIntoView({behavior: 'smooth'})
  //   })
  //
  //   return {
  //     currentMessage
  //   }
  // },
  methods: {
    async copyText (mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        this.$notify({
          type: 'success',
          message: 'Đã sao chép'
        })
      } catch ($e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.copy {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;

  img {
    width: 20px;
    vertical-align: unset;
    @media (max-width: 768px) {
      width: 18px;
      height: auto;
    }
  }
}
</style>
