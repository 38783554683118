<template>
  <div class="chat-body" ref="messageCont">
    <div class="container">
      <div class="more" v-if="more" @click="seeMore()"><span>Xem thêm</span></div>
      <template v-for="conversation in oldConversation">
        <div :key="conversation.conversationId">
          <p class="text-center">{{ formatTime(conversation.created_at) }}</p>
          <template v-for="question in conversation.questionList">
            <div class="wrapper" :key="'0'+ question.id">
              <Chat :chat="{ isAi: false, value: question.question}"/>
            </div>
            <div class="wrapper ai" :key="'1'+ question.id">
              <Chat :chat="{ isAi: true, value: question.answer}" @renderFinish="renderOld+=1"/>
            </div>
          </template>
        </div>
      </template>
      <p v-if="wrapper.length > 0 && oldConversation.length > 0" class="text-center">{{ wrapper[0].created_at }}</p>
      <div
        v-for="(chat, i) in wrapper"
        :key="i"
        class="wrapper"
        :class="{ ai: chat.isAi }"
      >
        <Chat :chat="chat" :key="i" @renderFinish="renderOld+=1"/>
      </div>
      <!--      <div class="d-block" style="height: 200px" v-if="wrapper.length == 1"></div>-->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {ref} from 'vue'
import Chat from '~/components/ai/Chat.vue'

export default {
  name: 'MessageCont',
  components: {
    Chat
  },
  data () {
    return {
      renderOld: 0,
      scrollFromBottom: 0,
      updateWrapper: false
    }
  },
  computed: {
    totalOld () {
      return this.oldConversation.reduce((acc, i) => acc + i.questionList.length, 0)
    }
  },
  props: {
    wrapper: {
      type: Array
    },
    oldConversation: {
      type: Array
    },
    more: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const messageCont = ref(null)
    return {messageCont}
  },
  updated () {
    if (this.updateWrapper) {
      this.scrollToBottom()
      this.updateWrapper = false
    }
  },
  mounted () {
    this.scrollToBottom()
  },
  watch: {
    'oldConversation.length': {
      handler (newVal, oldVal) {
        if (oldVal === 0) {
          this.scrollToBottom()
        } else {
          this.scrollToCurrent()
        }
      }
    },
    wrapper: {
      handler (newVal, oldVal) {
        this.updateWrapper = true
        this.scrollToBottom()
      }
    },
    renderOld: {
      handler (newVal, oldVal) {
        if (newVal === this.totalOld) {
          this.scrollToCurrent()
        }
      }
    }
  },
  methods: {
    formatTime (time) {
      const NOW = this.$moment()
      const TODAY = NOW.clone().startOf('day')
      const YESTERDAY = NOW.clone().subtract(1, 'days').startOf('day')
      // const A_WEEK_OLD = NOW.clone().subtract(7, 'days').startOf('day')
      time = this.$moment(time)
      if (time.isSame(TODAY, 'day')) {
        return time.format('HH:mm')
      } else if (time.isSame(YESTERDAY, 'day')) {
        return time.format('[Hôm qua] HH:mm')
      } else if (time.isSame(NOW, 'year')) {
        return time.format('HH:mm, D [Tháng] M')
      }
      // else if (time.isAfter(A_WEEK_OLD)) {
      //   const dayOfWeek = time.isoWeekday()
      //   switch (dayOfWeek) {
      //     case 1:
      //     case 2:
      //     case 3:
      //     case 4:
      //     case 5:
      //     case 6:
      //       return time.format(`[T${dayOfWeek + 1}] HH:mm`)
      //     case 7:
      //       return time.format('[CN] HH:mm')
      //   }
      //   return time.locale('vi').format('ddd HH:mm')
      // }
      return time.format('HH:mm, D [Tháng] M, YYYY')
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const div = this.$refs.messageCont
        if (div) {
          div.scroll({
            top: div.scrollHeight,
            behavior: 'smooth'
          })
          div.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          })
        }
      })
    },
    scrollToCurrent () {
      this.$nextTick(() => {
        const div = this.$refs.messageCont
        if (div) {
          const elementHeight = div.scrollHeight
          const visibleHeight = div.clientHeight

          div.scroll({
            top: elementHeight - visibleHeight - this.scrollFromBottom,
            behavior: 'instant'
          })
        }
      })
    },
    seeMore () {
      const div = this.$refs.messageCont
      const elementHeight = div.scrollHeight
      const visibleHeight = div.clientHeight
      const scrollTop = div.scrollTop
      this.scrollFromBottom = elementHeight - (scrollTop + visibleHeight)
      this.$emit('more')
    }
  }
}
</script>
